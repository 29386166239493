import React from 'react';
import Sidebar from '../../components/sidebar/Sidebar';
import SinglePost from '../../components/singlepost/SinglePost';

export default function Single() {
  return (
    <div className='single'>
      <SinglePost />
    </div>
  );
}
